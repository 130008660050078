/* Container principale */
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 90vh;
	width: 100vw;
}

.custom-select .ant-select-selection-item {
	color: #ffffff;
}

.custom-select .ant-select-selector {
	border: none !important;
}

/* Colore dell'icona (freccia giù) */
.custom-select .custom-icon {
	color: #d5a940; /* Colore dell'icona */
	font-size: 16px; /* Dimensione dell'icona */
}

/* Menu a tendina della Select */
.ant-select-dropdown {
	background-color: #003f4a; /* Colore di sfondo del menu a tendina */
	border-radius: 4px; /* Arrotonda i bordi del dropdown */
	border: none;
}

.access-button {
	background-color: #003f4a; /* Colore di sfondo del pulsante */
	color: #ffffff; /* Colore del testo del pulsante */
	border-radius: 40px; /* Arrotonda i bordi del pulsante */
	padding: 10px 20px; /* Spaziatura interna del pulsante */
	cursor: pointer; /* Cambia il cursore al passaggio del mouse */
	height: 50px; /* Altezza del pulsante */
	border: 3px solid #d5a940; /* Bordo del pulsante */
	font-family: "Inter", sans-serif; /* Assicurati di usare il font corretto */
	font-size: 16px; /* Dimensione del testo */
	font-weight: 500; /* Peso del testo */
	width: 150px;
}

.header-text {
	margin: 0;
	color: #fff;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 500;
}
.step-divider {
	width: 2px; /* Larghezza del divider */
	height: 50px; /* Altezza del divider, stessa altezza dell'icona */
	background-color: #bfc2c5; /* Colore del divider */
	margin-right: 20px; /* Spaziatura a destra del divider */
}

/* Sezione principale degli step */
.survey-content {
	display: flex;
	width: 65%;
	flex-direction: column;
	align-items: flex-start;
}

.survey-content-finalstep {
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: flex-start;
	padding-left: 100px;
	padding-right: 100px;
	padding-top: 50px;
}

.survey-title {
	color: #003f49;
	font-size: 46px;
	font-weight: 600;
	font-family: "Exo 2", sans-serif;
}

.survey-subtitle {
	color: #477673;
	font-size: 24px;
	font-weight: 600;
	font-family: "Exo 2", sans-serif;

	margin-bottom: 40px;
}

.survey-subtitle-bold {
	color: #477673;
	font-size: 18px;
	font-weight: 600;
	font-family: "Exo 2", sans-serif;
	text-align: left;
}

.input-description {
	color: #003f49;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
	font-family: "Inter", sans-serif;
}

.survey-input {
	height: 50px;
	border-radius: 16px;
	padding: 15px;
}

.input-subtitle {
	color: #737679;
	font-size: 14px;
	margin-bottom: 10px;
	font-family: "Inter", sans-serif;
	text-align: left;
}

.survey-step {
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	align-content: center;
	width: 100%;
	gap: 30px;
	padding: 20px;
	padding-left: 320px;
}

.footer-row {
	height: 12vh;
	padding-left: 80px;
	padding-right: 80px;
	background-color: #477673;
	width: 100vw;
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.survey-steps-confirm {
	flex-direction: row;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* 	height: 76vh; */

	/* 	width: 80%;
	gap: 20px; */
	/* 	padding: 20px; */
}
.survey-steps-intro {
	flex-direction: row;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	/* 	height: 76vh; */
	/* overflow-y: scroll; */

	/* 	width: 80%;
	gap: 20px; */
	/* 	padding: 20px; */
}

.surver-step-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 20px;
}

.surver-step-container-questions {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	gap: 20px;
}

.step-icon {
	width: 50px;
	height: 50px;
}

.survey-description {
	color: #737679;
	font-size: 16px;
	font-family: "Inter", sans-serif;
	line-height: 1.5;
	flex: 1; /* Assicura che il testo occupi lo spazio disponibile */
}

/* Footer Buttons */
.footer-button-next {
	background-color: #ffffff;
	color: #477673;
	border-radius: 50px;
	padding: 10px 20px;
	cursor: pointer;
	height: 50px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 500;
	min-width: 150px;
	width: fit-content;
}

.footer-button-back {
	background-color: #477673;
	color: #ffffff;
	border-radius: 50px;
	padding: 10px 20px;
	cursor: pointer;
	height: 50px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 500;
	width: 150px;
}

/* Gruppo di checkbox in due colonne */
.two-column-checkbox-group {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px; /* Spaziatura tra le checkbox sia verticalmente che orizzontalmente */
	width: 100%;
}

/* Stile delle checkbox */
.two-column-checkbox {
	display: inline-flex;
	align-items: center;
	border: 1px solid #d9d9d9;
	border-radius: 10px;
	padding: 8px 16px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #5c5c5c; /* Colore del testo per la checkbox non selezionata */
	background-color: #ffffff; /* Sfondo per la checkbox non selezionata */
	cursor: pointer;
	transition: all 0.3s ease; /* Transizione per effetto fluido */
	height: 65px;
	box-sizing: border-box; /* Assicura che la dimensione includa padding e border */
}

/* Stile della checkbox selezionata */
.two-column-checkbox .ant-checkbox-checked + span {
	color: #ffffff; /* Colore del testo per la checkbox selezionata */
}

.two-column-checkbox .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #4e7e7c; /* Sfondo del cerchio interno quando selezionato */
	border-color: #4e7e7c; /* Colore del bordo quando selezionato */
}

.two-column-checkbox .ant-checkbox-inner {
	border-color: #4e7e7c; /* Colore del bordo del cerchio quando non è selezionato */
}

/* Hover e Focus Effect */
.two-column-checkbox:hover {
	border-color: #4e7e7c;
}

.two-column-checkbox:focus-within {
	box-shadow: none;
}

.two-column-checkbox .ant-checkbox-wrapper-checked:focus-within {
	box-shadow: 0 0 0 2px rgba(78, 126, 124, 0.5);
}

/* Stili per i pulsanti radio */
.custom-radio-group .ant-radio-wrapper {
	display: inline-flex;
	min-height: 65px;
	height: fit-content;
	align-items: center;
	border: 1px solid #d9d9d9;
	border-radius: 10px;
	padding: 8px 16px;
	margin-right: 8px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #5c5c5c;
	background-color: #ffffff;
	cursor: pointer;
	transition: all 0.3s ease;
}

/* Stile per il pulsante radio selezionato */
.custom-radio-group .ant-radio-wrapper-checked {
	background-color: #4e7e7c;
	color: #ffffff;
	border-color: #4e7e7c;
}

/* Tondino interno del pulsante radio */
.custom-radio-group .ant-radio .ant-radio-inner {
	border-color: #4e7e7c;
}

.custom-radio-group .ant-radio-checked .ant-radio-inner {
	border-color: #ffffff;
	background-color: #4e7e7c;
}

.custom-radio-group .ant-radio-checked .ant-radio-inner::after {
	background-color: #ffffff;
}

/* Hover e Focus Effect per i pulsanti radio */
.custom-radio-group .ant-radio-wrapper:hover {
	border-color: #4e7e7c;
}

.custom-radio-group .ant-radio-wrapper:focus-within {
	box-shadow: none;
}

.custom-radio-group .ant-radio-wrapper-checked:focus-within {
	box-shadow: 0 0 0 2px rgba(78, 126, 124, 0.5);
}
/* Stile per il gruppo di checkbox con due colonne */
.two-column-checkbox-group {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* Due colonne */
	gap: 16px; /* Spaziatura tra gli elementi */
	width: 100%;
}
.custom-radio-group {
	display: flex;
	width: max-content !important;
}

/* Stile generale delle checkbox */
.custom-checkbox {
	display: inline-flex;
	align-items: center;
	border: 1px solid #d9d9d9;
	border-radius: 10px; /* Arrotondamento dei bordi */
	padding: 8px 16px;
	font-family: "Inter", sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #5c5c5c; /* Colore del testo per il pulsante non selezionato */
	background-color: #ffffff; /* Sfondo per il pulsante non selezionato */
	cursor: pointer; /* Cambia il cursore al passaggio del mouse */
	transition: all 0.3s ease; /* Transizione per effetto fluido */
	height: 65px;
	width: max-content; /* Larghezza al 100% per adattarsi alla griglia */
	box-sizing: border-box;
}

/* Stile per la checkbox selezionata - wrapper intero */
.custom-checkbox.ant-checkbox-wrapper-checked {
	background-color: #4e7e7c; /* Colore di sfondo per il wrapper quando è selezionato */
	color: #ffffff; /* Colore del testo per il pulsante selezionato */
	border-color: #4e7e7c;
}

/* Stile per l'elemento checkbox interno */
.custom-checkbox .ant-checkbox-inner {
	border-color: #4e7e7c; /* Colore del bordo del tondino quando non è selezionato */
}

/* Quando la checkbox è selezionata - interno */
.custom-checkbox.ant-checkbox-wrapper-checked .ant-checkbox-inner {
	background-color: #ffffff; /* Sfondo del tondino */
	border-color: #ffffff; /* Colore del bordo del tondino */
}

/* Colore del testo all'interno della checkbox selezionata */
.custom-checkbox.ant-checkbox-wrapper-checked span {
	color: #ffffff; /* Colore del testo quando il wrapper è selezionato */
}

/* Hover e Focus Effect */
.custom-checkbox:hover {
	border-color: #4e7e7c; /* Cambia il colore del bordo durante l'hover */
}

.custom-checkbox:focus-within {
	box-shadow: none;
}

.custom-checkbox.ant-checkbox-wrapper-checked:focus-within {
	box-shadow: 0 0 0 2px rgba(78, 126, 124, 0.5);
}

.ant-checkbox .ant-checkbox-inner:after {
	border-color: #4e7e7c;
}

.ant-checkbox-checked:hover .ant-checkbox-inner:after {
	border-color: #4e7e7c;
}
.custom-radio-group .ant-radio-wrapper:last-child {
	margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
	.survey-content {
		width: 90%;
	}

	.survey-content-finalstep {
		display: flex;
		flex-direction: column;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		padding-left: 0;
		padding-right: 0;
	}
	.two-column-checkbox-group {
		display: flex !important;
	}
	.footer-row {
		/* 	height: 12vh;
		padding-left: 10px;
		padding-right: 10px;
		background-color: #477673;
		width: 100vw;
		position: absolute;
		bottom: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center; */

		min-height: 12vh;
		padding-left: 10px;
		padding-right: 10px;
		background-color: #477673;
		width: 100vw;
		position: absolute;
		/* bottom: 10px; */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		height: auto;
	}
	.survey-step {
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 100px;
	}
	.survey-title {
		font-size: 36px;
		.survey-subtitle {
			font-size: 20px;
		}
	}
	.ant-radio-wrapper {
		display: inline-flex;
		min-height: 65px;
		height: fit-content;
		align-items: center;
		border: 1px solid #d9d9d9;
		border-radius: 10px;
		padding: 8px 16px;
		margin-right: 8px;
		font-family: "Inter", sans-serif;
		font-size: 16px;
		font-weight: 400;
		color: #5c5c5c;
		background-color: #ffffff;
		cursor: pointer;
		transition: all 0.3s ease;
	}
	.custom-radio-group {
		display: grid;
		width: fit-content !important;
	}
}

.custom-stepper .ant-steps-item-title {
	display: none; /* Nasconde i titoli degli step */
}

.custom-stepper .ant-steps-item-tail::after {
	background-color: #d9d9d9; /* Cambia il colore della linea */
	height: 2px; /* Riduce l'altezza della barra */
}

.custom-stepper .ant-steps-item-icon {
	width: 8px; /* Riduce la dimensione del punto */
	height: 8px;
	background-color: #477673; /* Cambia il colore del punto */
	border-radius: 50%;
	border: none; /* Rimuove i bordi */
}
.ant-steps
	.ant-steps-item-wait
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	width: 0px;
	height: 0px;
}
ant-steps-icon-dot {
	width: 10px;
	height: 10px;
}
.ant-steps
	.ant-steps-item-finish
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	background-color: #477673;
	width: 12px;
	height: 12px;
	margin-top: -2px;
}
.ant-steps.ant-steps-dot .ant-steps-item-tail::after {
	width: 100%;
}
.ant-steps
	.ant-steps-item-wait:last-child
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	display: block; /* Mostra l'icona solo per l'ultimo elemento */
	width: 12px;
	height: 12px;
	margin-top: -2px;
	margin-inline-start: 13px;
	background-color: #477673;
}

.ant-steps-item-tail {
	left: 0; /* Elimina lo spazio a sinistra della barra */
}

.ant-steps-item-icon {
	margin-left: 0; /* Riduce il margine a sinistra del cerchio */
}

.ant-steps-item {
	padding-left: 0; /* Elimina il padding iniziale */
}
.ant-steps.ant-steps-dot .ant-steps-item-tail {
	margin-inline: 78px 0px;
}
.ant-steps-horizontal .ant-steps-item:first-child .ant-steps-item-icon {
	margin-left: 82px; /* Specifico per il primo step */
}
.ant-steps-item-process .ant-steps-item-icon {
	background-color: #477673; /* Colore del punto attivo */
}

.ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
	margin-inline: 82px 0px;
}

.ant-steps
	.ant-steps-item-finish:not(:first-child)
	.ant-steps-item-icon
	> .ant-steps-icon
	.ant-steps-icon-dot {
	display: none;
}

.ant-steps
	.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-tail::after {
	background-color: #477673; /* Colore del punto attivo */
}
.agree .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #4e7e7c;
	border-color: #4e7e7c;
}

.agree .error .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #4e7e7c;
	border-color: red;
}

/* Stile per la checkbox quando è selezionata e l'utente passa il mouse sopra (hover) */
.agree .ant-checkbox-checked:hover .ant-checkbox-inner {
	background-color: #4e7e7c;
	border-color: #4e7e7c;
}

/* Stile per la checkbox non selezionata al passaggio del mouse (hover) */
.agree .ant-checkbox:hover .ant-checkbox-inner {
	border-color: #4e7e7c; /* Cambia il colore del bordo su hover */
}

/* Colore per il segno di spunta */
.agree .ant-checkbox .ant-checkbox-inner:after {
	border-color: #fff;
}

.error .ant-checkbox .ant-checkbox-inner {
	border-color: red;
}
.error {
	margin: 0 !important;
}
